<template>
  <div class="app-container speciality">
    <h3 class="title">搜索：{{Name}}</h3>
    <div class="mainbox" v-for="(item,index) in pagelist" :key="index">
      <div class="titlebox" @click="godetail(item)">
        <div>
          {{item.ProfessionName}}
          <i v-if="item.HasVideo" class="el-icon-caret-right iconbox"></i>
        </div>
        <!-- <div class="codebox">兴趣代码：{{item.InterestCode}}</div> -->
      </div>
      <div class="content">{{item.ProfessionDesc}}</div>
    </div>
    <div class="el-pagination" v-if="total>PageSize">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="PageSize"
        :current-page="PageIndex"
        @current-change="PageIndexChange"
        :total="total"
      ></el-pagination>
    </div>
    <div  class="defult" v-if="total<=0">没有搜索到对应的职业</div>
  </div>
</template>

<script>
import API from '@/api/config'

// import { ProfessionSearchByName } from "@/api/career/profession";
export default {
  data() {
    return {
      Name: "",
      pagelist: null,
      PageIndex: 1,
      PageSize: 10,
      total: 0
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: function(to, from) {
      //执行数据更新查询
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$route.query.Name) {
        this.Name = this.$route.query.Name;
        this.getdata();
      }
    },
    PageIndexChange(val) {
      this.PageIndex = val;
      this.getdata();
    },
    getdata() {
      API.Profession.ProfessionSearchByName({
        Name: this.Name,
        PageIndex: this.PageIndex,
        PageSize: this.PageSize
      }).then(res => {
        this.pagelist = res.data.Data;
        this.total = res.data.Total;
      });
    },
    godetail(val) {
      this.$router.push({
        path: "/profession-detail",
        query: { ID: val.ID }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.speciality {
  padding-bottom: 2rem;
  width: 66%;
  .title {
    color: rgba(var(--themecolor),1);
  }
  h4 {
    cursor: pointer;
  }
}
.content {
  white-space: pre-wrap;
  line-height: 1.5;
  color: #999;
}
.el-pagination {
  text-align: center;
  padding: 20px 0;
}
.mainbox {
  padding: 20px;
  &:hover {
    background: #f9f9f9;
  }
 .titlebox {
  display: flex;
  align-items: center;
  height: 60px;
  // background: #f9f9f9;
  cursor: pointer;
  line-height: 1.5;
  font-size: 20px;
  margin-bottom: 10px;
  &:hover {
    color: rgba(var(--themecolor),1);
  }
  .iconbox {
    border: 1px solid rgba(var(--themecolor),0.75);
    color: rgba(var(--themecolor),1);
    border-radius: 4px;
    margin-left: 6px;
  }
  .codebox {
    margin-left: 100px;
  }
}
}
.defult{
   font-size: 24px;
   color: #999;
   margin: 50px;
}

</style>